import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/dealing-with-burnout",
  "title": "Dealing with burnout - pushing yourself too hard",
  "excerpt": "Sometimes doing too much sneaks up on you",
  "tags": ["self-help", "development", "burnout"],
  "coverPhoto": {
    "cloudinaryAssetData": true,
    "cloudName": "dmocgebcq",
    "publicId": "portfolio/blog-covers/naomi-august-aLnqDp3B3YU-unsplash_xlg57a.jpg",
    "originalHeight": 3744,
    "originalWidth": 5616,
    "defaultBase64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMMXG/8HwAEwAI0Bj1bnwAAAABJRU5ErkJggg==",
    "defaultTracedSVG": "data:image/svg+xml,%3Csvg%20height%3D%229999%22%20viewBox%3D%220%200%209999%209999%22%20width%3D%229999%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m0%200h9999v9999h-9999z%22%20fill%3D%22%23f9fafb%22%2F%3E%3C%2Fsvg%3E"
  },
  "draft": false,
  "type": "blog",
  "date": "2021-09-04T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`It's been a bit since I've had time to write something I really wanted to. It's a bit disappointing because I did a big project and the whole time I was working on it all I could think of was the amount of articles I would get out of this. I'd learned so many new technologies and was doing so many new things, it was great.`}</p>
    <p>{`And then as time passed I never wrote anything down. I just moved onto the next learning experience or jumped around to something else. I kept buying tutorials, learning about the newest shiny toy, watching youtube videos or attending online conferences.`}</p>
    <p>{`It took me awhile but I realized I didn't really want to write anything down. I was tired of learning (something exceptionally rare for me). I usually find so much fun and enjoyment in doing coding, even on off-hours. But, it had faded for me. I was doing things I felt pressured to do and I had expected of myself. Things weren't going as smoothly as I would have liked it to. So everything just felt like a massive grind.`}</p>
    <p>{`I think at this point you know what's going on. If not, I'd started to burnout. In the beginning of the pandemic, I decided to channel a lot of my energy into a large project, I mean every moment after work and on the weekends. It kept my mind busy and I didn't have to worry about the news or what was going on at work, or whatever. I knew I'd get off my now remote job and be able to do something I was passionate about.`}</p>
    <p>{`This project was a good 6-7 month endeavor of non-stop work, which I actually did finish. But then I tried to find similar projects to distract myself with and those never seemed to work out. I'm not sure if I was already tired then or just the constant pushing out of my comfort zone took it's toll. Either way burnout sucked up my passion and a bunch of side projects I had lined up are now just lost in a bunch of notebooks.`}</p>
    <p>{`The good thing out of all of this is I took a digital vacation of sorts. I wasn't on twitter nearly as much, didn't write any posts, barely did any design or development. I started getting some non-computer things to do. Started to crochet, it's not great but it's something nice and tactial to do. Tried my hand at keto dessert making, for health and an interesting life skill. Also got back into playing video games, something that I enjoyed but would sacrifice for projects and learning.`}</p>
    <p>{`It's been a bit, but I think I'm doing better now. I'll keep an eye on my balance and try to not push myself so hard again. Probably was a poor way to cope with the outside world going to hell and trying to adjust to this new reality.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      